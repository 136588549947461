/**=====================
     Tap to top scss
==========================**/
.theme-option {
    position: fixed;
    z-index: 6;
    transition: all .3s ease-in-out;
    cursor: pointer;
    bottom: 20px;
    right: 24px;

    .back-to-top {
        background-color: var(--mainColor);
        padding: 0;
        border-radius: 50%;
        z-index: 1;
        margin-top: 12px;

        .back-to-top{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;

            i {
                color: #fff;
            }
        }
    }
}

.display-none{
    display: none;
}

.theme-option-widgets{
    bottom: 90px;
}